.submitSample {
  &__title {
    margin-bottom: 22px;
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: var(--grayscale-200);
    margin: 33px 0;
  }
}
