.actionButtons {
  display: flex;
  margin: 24px 0 0 32px;
}

.actionButton {
  width: 123px;
  margin-right: 12px;

  &_loading {
    cursor: none;
    pointer-events: none;
    width: 42px;
    padding: 0;
  }

  svg {
    margin-right: 8px;

    path {
      stroke: var(--providers-600);
    }
  }

  &_patients {
    svg {
      path {
        stroke: var(--patients-600);
      }
    }

    &.actionButton_success {
      color: var(--patients-600) !important;
    }
  }

  &_success {
    border: none !important;
    cursor: none;
    pointer-events: none;
    width: 170px;
    color: var(--providers-600) !important;
  }

  &_error {
    border: none !important;
    cursor: none;
    pointer-events: none;
    width: 170px;
    color: var(--error) !important;

    svg {
      path {
        stroke: var(--error);
      }
    }
  }

  &__loader {
    width: 24px;
    height: 24px;
    border: 2px solid var(--grayscale-50);
    border-bottom-color: rgb(255 255 255 / 50%);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% { transform: rotate(0deg) }

    100% { transform: rotate(360deg) }
  }
}
