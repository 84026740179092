.borderedLink {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--providers-600);
  text-decoration: none !important;
  padding: 23px 46px 23px 23px;
  transition: background-color .2s ease-in-out;

  > svg {
    min-width: 24px;
    min-height: 24px;
  }

  svg {
    margin-right: 12px;

    path {
      stroke: var(--providers-600);
    }
  }

  &:hover {
    background-color: var(--providers-100);
  }

  &_patients {
    border-color: var(--patients-600);

    svg {
      path {
        stroke: var(--patients-600);
      }
    }

    &:hover {
      background-color: var(--patients-100);
    }
  }

  &__arrow {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
}
