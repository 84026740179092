.button {
  cursor: pointer;
  color: var(--grayscale-50);
  padding: 0 17px;
  min-height: 36px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--providers-500);
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  &_M {
    min-height: 39px;
  }

  &_L {
    min-height: 42px;
  }

  &:hover {
    background-color: var(--providers-600);
  }

  &:active {
    background-color: var(--providers-700);
  }

  &.secondary {
    background-color: var(--grayscale-50);
    color: var(--providers-600);

    &:hover {
      background-color: var(--providers-100);
    }

    &:active {
      background-color: var(--providers-200);
    }
  }

  &_patients {
    background-color: var(--patients-500);

    &:hover {
      background-color: var(--patients-600);
    }

    &:active {
      background-color: var(--patients-700);
    }

    &.secondary {
      color: var(--patients-600);

      &:hover {
        background-color: var(--patients-100);
      }

      &:active {
        background-color: var(--patients-200);
      }
    }
  }

  &.ghost {
    color: var(--patients-950);
    background-color: transparent;
    border: 1px solid var(--grayscale-200);

    &:hover {
      border-color: var(--patients-950);
      background-color: transparent;
    }
  }
}
