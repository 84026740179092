@import 'scss/media';

.howItWorksItem {
  width: 264px;
  height: 497px;
  background-color: var(--patients-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 28px 0;

  @include for-size(small-screens) {
    width: 210px;
    height: 560px;
    padding: 41px 16px 0;
  }

  @include for-size(tablets) {
    width: 170px;
    height: 600px;
    padding: 41px 8px 0;
  }

  @include for-size(mobiles) {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    padding: 41px 28px 24px;

    p {
      text-align: justify;
    }
  }

  &:last-child {
    @include for-size(mobiles) {
      margin-bottom: 0;
    }
  }

  &__image {
    width: 164px;
    height: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;

    @include for-size(tablets) {
      width: 128px;
      height: 128px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
