@use 'scss/media' as media;

.mobileMenu {
  display: none;
  opacity: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgb(220 220 220 / 25%);
  backdrop-filter: saturate(120%) blur(20px);
  right: -9999px;
  transition: opacity .3s ease-in-out;
  z-index: 3333;
  padding: 16px;
  pointer-events: none;

  @include media.for-size(tablets) {
    padding: 16px 32px;
    display: block;
  }

  @include media.for-size(mobiles) {
    display: block;
  }

  a {
    text-decoration: none;
    color: var(--patients-950)
  }

  &__logo {
    width: 48px;
    height: 48px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_opened {
    pointer-events: all;
    opacity: 1;
    right: 0;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  &__content {
    max-height: calc(100% - 71px);
    overflow-y: scroll;
    white-space: pre-line;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 100px;
  }

  &__submenu {
    display: flex;
    flex-direction: column;
    padding-left: 17px;
    padding-bottom: 2px;
    border-left: 2px solid rgb(42 112 119 / 20%);
    margin-bottom: 26px;

    &_patients {
      border-left-color: rgb(29 106 172 / 20%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    margin-bottom: 14px;
    margin-top: 26px;

    p {
      font-size: 20px;
      line-height: 32px;
    }

    &_sub {
      margin-bottom: 11px;
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__close {
    width: 48px;
    height: 48px;
    cursor: pointer;

    rect {
      fill: var(--providers-600);
    }

    &_patients {
      rect {
        fill: var(--patients-600)
      }
    }
  }
}
