@import 'scss/media';

.welcomePage {
  &__title {
    white-space: pre-line;
    width: 630px;
    margin: 41px 0 63px;

    @include for-size(small-screens) {
      margin: 3px 0 33px;
    }

    @include for-size(tablets) {
      width: 570px;
      margin: 2px 0 58px;
    }

    @include for-size(mobiles) {
      width: 100%;
      margin: 2px 0 42px;
    }
  }

  &__ourProducts {
    display: flex;
    margin-top: 20px;

    a {
      text-decoration: none;
    }

    @include for-size(small-screens) {
      flex-wrap: wrap;
    }

    @include for-size(tablets) {
      flex-wrap: wrap;
    }

    @include for-size(mobiles) {
      margin-top: 10px;
      flex-direction: column;
    }
  }

  &__actions {
    margin: 0 24px;

    @include for-size(small-screens) {
      display: flex;
      flex: 0 0 100%;
      order: 2;
      margin: 24px 0 0;
    }

    @include for-size(tablets) {
      display: flex;
      flex: 0 0 100%;
      order: 2;
      margin: 24px 0 0;
    }

    @include for-size(mobiles) {
      margin: 0;
    }
  }

  &__about {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    height: 410px;

    @include for-size(tablets) {
      height: auto;
    }

    @include for-size(mobiles) {
      height: auto;
    }
  }

  &__company {
    margin-top: 29px;

    @include for-size(small-screens) {
      margin-top: 21px;
    }

    @include for-size(tablets) {
      margin-top: 23px;
    }

    @include for-size(mobiles) {
      margin-top: 21px;
    }
  }

  &__publications {
    display: flex;
    height: 100%;

    @include for-size(tablets) {
      height: 448px;
    }

    @include for-size(mobiles) {
      flex-direction: column;
      width: 100%;
    }
  }
}
