.specifications {
  .heading {
    margin-bottom: 15px;
  }
}

.specification {
  display: flex;
  align-items: center;
  margin-bottom: 31px;

  &:last-child {
    margin-bottom: 0;
  }

  &__turnaroundTime {
    position: relative;
    z-index: -1;
    background-color: var(--grayscale-200);
    height: 32px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 0 21px 0 29px;
    margin-left: -17px;
    white-space: nowrap;
  }

  > p {
    margin-left: 15px;
  }
}
