@import 'scss/media';

.aboutUs {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-height: 346px;
  overflow: hidden;
  background-color: var(--providers-50);
  padding: 32px 51px 32px 33px;
  transition: all .2s ease-in-out;

  @include for-size(mobiles) {
    flex-direction: column;
    max-height: 546px;
  }

  &_patients {
    background-color: var(--patients-50);
  }

  &_opened {
    max-height: 9999px;
  }

  a {
    text-decoration-color: transparent;
  }

  &__links {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__rightBlock {
    position: relative;
    width: 884px;

    @include for-size(small-screens) {
      width: 700px;
    }

    @include for-size(tablets) {
      width: 500px;
    }

    @include for-size(mobiles) {
      width: 100%;
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid var(--grayscale-200);
    }

    p {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    position: absolute;
    bottom: -2px;
    left: 53%;
    transform: translateX(-50%);
    z-index: 1;

    @include for-size(mobiles) {
      bottom: auto;
      top: 150px;
    }
  }

  &__blur {
    background: var(--grayscale-50);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    filter: blur(20px);
  }
}
