@import 'scss/media';

.personalizedReport {
  margin-top: 15px;
  margin-bottom: 24px;

  &__example {
    margin: 15px 0;
  }

  &__items {
    display: flex;

    @include for-size(small-screens) {
      flex-wrap: wrap;
    }

    @include for-size(tablets) {
      flex-wrap: wrap;
    }

    @include for-size(mobiles) {
      flex-wrap: wrap;
    }
  }
}

.personalizedReportItem {
  width: 336px;
  height: 410px;
  background-color: var(--providers-50);
  margin-right: 24px;
  padding: 31px 24px 10px;

  @include for-size(small-screens) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include for-size(tablets) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include for-size(mobiles) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__circle {
    overflow: hidden;
    position: relative;
    border-radius: 120px;
    background-color: var(--grayscale-50);
    border: 1px solid var(--providers-100);
    min-width: 128px;
    min-height: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    padding-top: 10px;

    &_withoutPadding {
      padding-top: 0;
    }
  }

  &_patients {
    background-color: var(--patients-50);

    .personalizedReportItem__circle {
      border-color: var(--patients-100);
    }
  }

  &:last-child {
    margin-right: 0;
  }


  > span {
    display: block;
  }

  &__topLine {
    display: flex;
    align-items: center;
    height: 128px;
    margin-bottom: 24px;
  }

  &__order {
    position: absolute;
    right: 23px;
    top: 22px;
  }

  img {
    margin-bottom: 12px;
  }
}
