@import 'scss/media';

.headerWrapper {
  width: 100%;
  top: 0;
  z-index: 1111;
  transition: all .3s ease-in-out;
  border-bottom: 1px solid transparent;

  &_inMotion {
    background-color: rgb(220 220 220 / 25%);
    backdrop-filter: saturate(120%) blur(20px);
    border-bottom-color: var(--grayscale-100);
  }

  &_white {
    background-color: var(--grayscale-50);
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  width: var(--container-width);
  height: 80px;
  margin: 0 auto;

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @include for-size(mobiles) {
      justify-content: space-between;
    }
  }

  &__hamburger {
    display: none;
    width: 48px;
    height: 48px;
    cursor: pointer;

    @include for-size(tablets) {
      display: block;
      margin-left: auto;
    }

    @include for-size(mobiles) {
      display: block;
    }
  }
}
