@import 'scss/media';

.catalogPanel {
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid var(--grayscale-200);
  margin-bottom: 24px;
  padding: 23px 0 23px 23px;

  @include for-size(mobiles) {
    flex-direction: column;
    padding-right: 23px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  &__left {
    border-right: 1px solid var(--grayscale-200);
    width: 432px;
    min-width: 432px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(mobiles) {
      width: 100%;
      min-width: 100%;
      border-right: none;
      padding-right: 0;
      margin-right: 23px;
      border-bottom: 1px solid var(--grayscale-200);
      padding-bottom: 30px;
    }
  }

  &__topLine {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include for-size(mobiles) {
      max-width: 100%;
    }
  }

  &__title {
    max-width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--providers-600);
    transition: all .2s ease-in-out;
    margin-bottom: 10px;

    @include for-size(mobiles) {
      max-width: 100%;
    }

    &_patients {
      color: var(--patients-600);
    }
  }

  &__genes {
    text-align: right;

    @include for-size(tablets) {
      text-align: left;
    }

    @include for-size(mobiles) {
      margin-left: auto;
    }
  }

  &__bottomLine {
    display: flex;
    justify-content: space-between;
  }

  &__icons {
    display: flex;

    > div {
      margin-right: 8px;
    }
  }

  &__docs {
    display: flex;
    align-items: center;

    &_sampleReport {
      text-decoration: underline !important;
      text-decoration-color: transparent !important;
      transition: all .2s ease-in-out;

      &:hover {
        text-decoration-color: inherit !important;
      }
    }
  }

  &__docsCount {
    position: relative;
    z-index: -1;
    background-color: var(--providers-50);
    height: 32px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 0 17px 0 29px;
    margin-left: -17px;
    white-space: nowrap;

    &_patients {
      background-color: var(--patients-50);
    }
  }

  &__content {
    padding: 9px 0 1px 23px;
    flex-grow: 1;

    @include for-size(mobiles) {
      padding: 27px 0 0;
    }
  }

  &__contentTitle {
    padding-bottom: 27px;
    border-bottom: 1px solid var(--grayscale-200);
    margin-right: 23px;

    @include for-size(mobiles) {
      margin-right: 0;
    }
  }

  &__subsets {
    margin-right: 23px;

    @include for-size(mobiles) {
      margin-right: 0;
    }
  }

  &__conditions {
    margin-top: 16px;
  }
}
