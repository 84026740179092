.events {
  margin-top: 29px;

  a {
    color: var(--patients-950);
    text-decoration-color: transparent;
  }

  &__list {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
