@import 'scss/media';

.compliance {
  display: flex;
  padding-bottom: 35px;

  @include for-size(mobiles) {
    flex-direction: column-reverse;
  }

  a {
    text-decoration-color: transparent;
  }

  &__content {
    padding-right: 32px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 456px;
    width: 456px;

    @include for-size(small-screens) {
      min-width: 400px;
      width: 400px;
    }

    @include for-size(tablets) {
      min-width: 300px;
      width: 300px;
    }

    @include for-size(mobiles) {
      min-width: unset;
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
