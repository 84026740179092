@import 'scss/media';

.form {
  width: calc(100% - 456px); // 456px is a width of notifications block
  padding-right: 24px;

  @include for-size(small-screens) {
    width: calc(100% - 300px);
  }

  @include for-size(tablets) {
    width: calc(100% - 300px);
  }

  @include for-size(mobiles) {
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--grayscale-200);
    padding-left: 30px;
    margin-top: 34px;

    &:first-child {
      margin-top: 0;
    }

    .heading_H6 {
      margin-bottom: 27px;
    }
  }

  &__line {
    display: flex;

    @include for-size(small-screens) {
      flex-direction: column;
    }

    @include for-size(mobiles) {
      flex-direction: column;
    }

    > label {
      margin-right: 12px;

      @include for-size(small-screens) {
        margin-right: 0;
      }

      @include for-size(mobiles) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }

  &__stateField {
    max-width: 145px;

    @include for-size(small-screens) {
      max-width: 100%;
    }

    @include for-size(mobiles) {
      max-width: 100%;
    }
  }

  &__zipField {
    max-width: 120px;

    @include for-size(small-screens) {
      max-width: 100%;
    }

    @include for-size(mobiles) {
      max-width: 100%;
    }
  }
}
