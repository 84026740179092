.panelMeta {
  display: flex;
  align-items: center;

  &__block {
    text-align: right;
    margin-right: 32px;
  }

  &__howToOrder {
    svg {
      margin-right: 8px;

      path {
        stroke: var(--grayscale-50);
      }
    }
  }
}
