@import 'scss/media';

:root {
  --text-top-value: 155px;
  --text-bottom-bump-value: 170px;
  
  @include for-size(small-screens) {
    --text-top-value: 105px;
  }

  @include for-size(tablets) {
    --text-top-value: 105px;
  }

  @include for-size(mobiles) {
    --text-top-value: 40px;
    --text-bottom-bump-value: 40px;
  }
}

.lookingForSection {
  position: relative;
  width: 456px;
  overflow: hidden;
  height: 424px;
  cursor: pointer;
  color: var(--patients-950);

  @include for-size(small-screens) {
    width: 556px;
    height: 320px;
  }

  @include for-size(tablets) {
    width: 436px;
    height: 320px;
  }

  @include for-size(mobiles) {
    width: 100%;
    height: 200px;
  }

  &__bg {
    width: 100%;
    height: 100%;
    border: 1px solid var(--patients-950);
    transition: all .2s ease-in-out;

    &:hover {
      background-color: var(--providers-500);
      border-color: var(--providers-500);
      color: var(--grayscale-50);
    }

    &_patients:hover {
      background-color: var(--patients-500);
      border-color: var(--patients-500);
    }
  }

  &__sampleKit {
    position: absolute;
    left: -98px;
    top: 52px;
    width: 355px;
    height: 320px;
    background-image: url('assets/sample_kit.png');
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform .25s ease-in-out;

    @include for-size(small-screens) {
      top: 0;
      left: 0;
    }

    @include for-size(tablets) {
      top: 0;
      left: -118px;
    }

    @include for-size(mobiles) {
      top: -60px;
      left: -196px;
    }
  }

  &:hover &__sampleKit {
    transform: scale(1.15);
  }

  &__text {
    position: absolute;
    top: var(--text-top-value);
    right: 35px;
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  &.animated &__text {
    animation: bottom-bump .5s ease-in-out;
  }

  &:hover &__text {
    animation: top-bump .5s ease-in-out;
    animation-fill-mode: forwards;
  }

  &__arrow {
    position: absolute;
    right: 96px;
    bottom: 32px;
    opacity: 0;
    transition: opacity .25s ease;

    @include for-size(mobiles) {
      display: none;
    }
  }

  &.animated &__arrow {
    animation: arrow-left-bump .5s ease-in-out;
  }

  &:hover &__arrow {
    animation: arrow-right-bump .5s ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}

@keyframes top-bump {
  0% { top: var(--text-top-value); }
  50% { top: 30px; }
  100% { top: 45px; }
}

@keyframes bottom-bump {
  0% { top: 45px; }
  50% { top: var(--text-bottom-bump-value); }
  100% { top: var(--text-top-value); }
}

@keyframes arrow-right-bump {
  0% { right: 96px; }
  50% { right: 20px; }
  100% { right: 32px; }
}

@keyframes arrow-left-bump {
  0% { right: 32px; }
  50% { right: 108px; }
  100% { right: 96px }
}
