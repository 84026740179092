.select {
  display: flex;
  align-items: center;

  select {
    margin-left: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 14px;
    line-height: 20px;
    color: var(--providers-600);
    font-weight: 700;
    transition: color .2s ease-in-out;

    &.patients {
      color: var(--patients-600);
    }

    &:hover {
      color: var(--patients-950);
    }

    &:focus {
      outline: none;
    }
  }
}

#tmp-select {
  display: none;
}
