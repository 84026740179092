@import 'scss/media';

.about {
  a {
    text-decoration-color: transparent;
  }

  &__bottomPanel {
    display: flex;
    margin-top: 64px;

    @include for-size(tablets) {
      flex-wrap: wrap;
    }

    @include for-size(small-screens) {
      flex-wrap: wrap;
    }

    @include for-size(mobiles) {
      padding-left: 0;
      flex-direction: column;
      margin-top: 32px;
    }
  }
}
