.genesList {
  display: flex;
  flex-wrap: wrap;

  &__moreButton {
    span {
     font-weight: 500;
    }
  }
}
