@import 'scss/media';

.publication {
  display: flex;
  margin-top: 26px;

  @include for-size(mobiles) {
    flex-direction: column;
    margin-top: 0;
  }

  a {
    text-decoration-color: transparent;
  }

  &__tags {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__leftColumn {
    width: 336px;
    min-width: 336px;
    margin-right: 22px;

    @include for-size(small-screens) {
      width: 300px;
      min-width: 300px;
      margin-right: 20px;
    }

    @include for-size(tablets) {
      width: 200px;
      min-width: 200px;
      margin-right: 16px;
    }

    @include for-size(mobiles) {
      margin-right: 0;
      width: 100%;
      min-width: unset;
      margin-bottom: 24px;
    }
  }

  &__date {
    margin-top: 7px;
    margin-bottom: 62px;

    @include for-size(mobiles) {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &__authors {
    margin-top: 11px;
    margin-bottom: 62px;

    @include for-size(mobiles) {
      margin-bottom: 18px;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__image {
    max-width: 100%;
  }
}
