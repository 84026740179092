@import 'scss/media';

.certificate {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grayscale-50);
  padding: 23px 16px 25px;

  @include for-size(mobiles) {
    flex-direction: column;
    align-items: flex-start;
  }

  &_patients {
    background-color: var(--patients-50);
  }

  &__title {
    max-width: 420px;
    margin-right: 12px;
    white-space: pre-line;
    transition: text-decoration-color .2s ease-in-out;

    &:hover {
      text-decoration-color: inherit;
    }
  }

  &__leftColumn {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &__rightColumn {
    display: flex;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__property {
    margin-left: 32px;

    &:first-child {
      margin-left: 0;
    }

    @include for-size(mobiles) {
      margin-top: 16px;
      margin-left: 44px;

      &:first-child {
        margin-left: 44px;
      }
    }

    &Title {
      color: var(--grayscale-400);
      white-space: nowrap;
    }
  }
}
