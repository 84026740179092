@import 'scss/media';

.search {
  margin-left: 30px;
  position: relative;
  width: 240px;
  height: 42px;

  @include for-size(mobiles) {
    display: none;
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: 60px;
    border: 1px solid var(--grayscale-200);
    padding: 0 40px 0 18px;
    font-size: 14px;
    line-height: 20px;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    top: 9px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
  }
}
