@import 'scss/media';

.action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 456px;
  height: 200px;
  padding: 32px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;

    @include for-size(mobiles) {
      margin-bottom: 12px;
    }
  }

  @include for-size(small-screens) {
    margin-right: 24px;
    margin-bottom: 0;
    width: 556px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include for-size(tablets) {
    margin-right: 24px;
    margin-bottom: 0;
    width: 436px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include for-size(mobiles) {
    padding: 27px 21px 32px 23px;
    width: 100%;
    height: 200px;
    margin-bottom: 12px;
  }

  &__arrow {
    position: absolute;
    right: 80px;
    top: 35px;
    opacity: 0;
    transition: opacity .25s ease;

    &_black path {
      fill: var(--patients-950);
    }
  }

  &.animated &__arrow {
    animation: action-arrow-left-bump .25s ease-in-out;
  }

  &:hover &__arrow {
    animation: action-arrow-right-bump .25s ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }
}

@keyframes action-arrow-right-bump {
  0% { right: 80px; }
  100% { right: 32px; }
}

@keyframes action-arrow-left-bump {
  0% { right: 32px; }
  100% { right: 80px }
}
