@import 'scss/media';

.downloadFile {
  position: relative;
  height: 80px;

  @include for-size(mobiles) {
    width: 100%;
  }

  a {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  &__info {
    min-width: 200px;
    position: relative;
    margin-left: 60px;
    margin-top: 9px;
  }

  &__description {
    position: absolute;
    left: 0;
    top: 22px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__visible {
    transition: all .3s ease-in-out;
    opacity: 1;
    z-index: 2;
  }

  &__hidden {
    transition: all .3s ease-in-out;
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    .downloadFile__visible {
      opacity: 0;
      z-index: 1;
    }

    .downloadFile__hidden {
      opacity: 1;
      z-index: 2;
    }
  }

  &__title {
    display: block;
    color: var(--providers-600);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_patients {
      color: var(--patients-600);
    }
  }
}
