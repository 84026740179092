@import 'scss/media';

.publications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 456px;
  border: 1px solid var(--patients-950);
  padding: 32px 31px 33px;
  margin-left: 24px;

  a {
    text-decoration-color: transparent;
    text-decoration-thickness: 2px;
  }

  @include for-size(small-screens) {
    width: 362px;
  }

  @include for-size(tablets) {
    padding-top: 37px;
    width: 436px;
    margin-top: 24px;

    &:first-child {
      margin-left: 0;
    }
  }

  @include for-size(mobiles) {
    margin-left: 0;
    padding-top: 37px;
    width: 100%;
    margin-top: 24px;
    height: 424px;
  }

  &__header {
    border-bottom: 1px solid var(--grayscale-200);
    padding-bottom: 13px;

    @include for-size(tablets) {
      padding-bottom: 18px;
    }

    @include for-size(mobiles) {
      padding-bottom: 18px;
    }
  }

  &__list {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
  }

  &__link {
    color: var(--patients-950);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 47px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__listItem {
    margin-bottom: 32px;
    position: relative;
    transition: text-decoration-color 0.2s ease-in-out;

    &:hover {
      text-decoration-color: var(--patients-950);
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include for-size(tablets) {
      margin-bottom: 24px;
    }
  }

  &__showAll {
    display: block;
    position: relative;
    transition: text-decoration-color 0.2s ease-in-out;

    p {
      color: var(--patients-950);
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      background-image: url("~app/icons/ChevronRight.svg");
      width: 24px;
      height: 24px;
    }

    &:hover {
      text-decoration-color: var(--patients-950);
    }
  }

  &__separator {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: var(--grayscale-200);
  }
}
