@import 'scss/media';

.pageHeaderActions {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  @include for-size(mobiles) {
    width: 100%;
    justify-content: space-between;
  }
}
