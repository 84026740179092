.loader {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 60%);

  &__circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 74px;
    height: 74px;
    background-repeat: no-repeat;
    animation: spin 0.8s steps(17) infinite;
  }
}

@keyframes spin {
  100% { background-position: -1258px 0 }
}
