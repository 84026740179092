@import 'scss/media';

:root {
  --font-size-58: 58px;
  --font-size-58-lh: 70px;
  --font-size-42: 42px;
  --font-size-42-lh: 48px;
  --font-size-32: 32px;
  --font-size-32-lh: 48px;
  --font-size-28: 28px;
  --font-size-28-lh: 34px;
  --font-size-20: 20px;
  --font-size-20-lh: 32px;
}

@include for-size(tablets) {
  :root {
    --font-size-58: 48px;
    --font-size-58-lh: 58px;
    --font-size-32: 28px;
    --font-size-32-lh: 34px;
    --font-size-28: 24px;
    --font-size-28-lh: 24px;
    --font-size-20: 18px;
    --font-size-20-lh: 32px;
  }
}

@include for-size(mobiles) {
  :root {
    --font-size-58: 40px;
    --font-size-58-lh: 48px;
    --font-size-42: 36px;
    --font-size-42-lh: 48px;
    --font-size-32: 26px;
    --font-size-32-lh: 32px;
    --font-size-28: 24px;
    --font-size-28-lh: 24px;
    --font-size-20: 18px;
    --font-size-20-lh: 32px;
  }
}
