:root {
  // providers
  --providers-50: #F3FAFA;
  --providers-100: #D6F1F0;
  --providers-200: #ADE2E1;
  --providers-300: #7CCACC;
  --providers-400: #51ABB0;
  --providers-500: #3B99A0;
  --providers-600: #2A7077;
  --providers-700: #255A60;
  --providers-800: #21494E;
  --providers-900: #203D41;
  --providers-950: #0D2326;

  // patients
  --patients-50: #F2F8FD;
  --patients-100: #E4EEFA;
  --patients-200: #C3DDF4;
  --patients-300: #8FC2EA;
  --patients-400: #61AAE0;
  --patients-500: #2D86CA;
  --patients-600: #1D6AAC;
  --patients-700: #19568B;
  --patients-800: #194973;
  --patients-900: #1A3E60;
  --patients-950: #03122E;

  // grayscale
  --grayscale-50: #FFF;
  --grayscale-100: #DCDCDC;
  --grayscale-200: #EFEFEF;
  --grayscale-300: #BDBDBD;
  --grayscale-400: #989898;
  --grayscale-500: #7C7C7C;
  --grayscale-600: #656565;
  --grayscale-700: #525252;
  --grayscale-800: #464646;
  --grayscale-900: #3D3D3D;
  --grayscale-950: #292929;

  // accent
  --error: #ED5B3B;
  --warning: #F28020;
  --attention: #FFC805;
  --accepted: #18CE94;
}
