@import 'scss/media';

.publications {
  margin-top: 5px;

  a {
    text-decoration-color: transparent;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 48px 0 40px;
    border-bottom: 1px solid var(--grayscale-200);

    @include for-size(mobiles) {
      flex-direction: column;
    }

    &:last-child {
     border-bottom: none;
    }

    &Title {
      width: 75%;

      &:hover {
        text-decoration-color: inherit;
      }

      @include for-size(mobiles) {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 24px;
    max-width: 20%;

    @include for-size(small-screens) {
      padding-right: 12px;
    }

    @include for-size(tablets) {
      padding-right: 12px;
    }

    @include for-size(mobiles) {
      max-width: 100%;
      padding-right: 12px;
    }
  }

  &__tagsList {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;

    > div {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
