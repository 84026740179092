@import 'scss/media';

.overlay {
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--grayscale-950);
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 2222;
  transition: all .3s ease-in-out;
  animation: overlay-appear .2s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes overlay-appear {
  from { opacity: 0 }
  to { opacity: .4 }
}

.rightSidebar {
  opacity: 0;
  top: 0;
  position: fixed;
  width: 640px;
  height: 100%;
  background-color: var(--grayscale-50);
  right: -3333px;
  transition: all .3s ease-in-out;
  z-index: 3333;
  padding: 24px;

  @include for-size(mobiles) {
    width: 100%;
  }

  &_opened {
    opacity: 1;
    right: 0;
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__content {
    max-height: calc(100% - 90px);
    overflow-y: scroll;
    white-space: pre-line;
  }

  &__close {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: var(--grayscale-200);

    &::before, &::after {
      content: '';
      position: absolute;
      top: 23px;
      left: 15px;
      height: 2px;
      width: 20px;
      background-color: var(--patients-950);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }
}
