@import 'scss/media';

.howItWorks {
  &__items {
    display: flex;
    align-items: center;
    margin: 33px 0 32px;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__searchAtLink {
    margin-bottom: 32px;
  }

  &__credentials {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    @include for-size(mobiles) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__notification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 66px;
    padding: 23px 40px 25px 32px;
    width: 456px;
    min-width: 456px;
    background-color: var(--providers-50);

    @include for-size(small-screens) {
      width: 300px;
      min-width: 300px;
    }

    @include for-size(tablets) {
      width: 300px;
      min-width: 300px;
    }

    @include for-size(mobiles) {
      width: 100%;
      min-width: 100%;
      margin-top: 32px;
      margin-bottom: 0;
    }
  }

  &__notificationBlock {
    display: flex;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    > svg {
      margin-top: 5px;
      min-width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
}
