@import 'scss/media';

.sidebar {
  position: sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: -12px;
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @include for-size(tablets) {
    flex-direction: row;
    margin: 0 auto;
    width: var(--container-width);
  }

  @include for-size(mobiles) {
    flex-direction: row;
    margin: 0 auto;
    width: var(--container-width);
  }

  &__item {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-left: 12px;
    padding-right: 36px;
    cursor: pointer;
    color: var(--providers-600);
    line-height: 52px;
    height: 52px;
    opacity: .75;
    transition: background-color .1s ease-in-out;
    white-space: nowrap;

    @include for-size(tablets) {
      padding-right: 12px;
    }

    @include for-size(mobiles) {
      padding-right: 12px;
    }

    &.active {
      opacity: 1;
      background-color: var(--providers-50);

      &::after {
        content: "";
        position: absolute;
        right: 22px;
        top: 20px;
        border: solid var(--patients-950);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);

        @include for-size(tablets) {
          display: none;
        }

        @include for-size(mobiles) {
          display: none;
        }
      }
    }

    &:hover {
      background-color: var(--providers-50);
    }

    &_patients {
      color: var(--patients-600);

      &.active {
        opacity: 1;
        background-color: var(--patients-50);
      }

      &:hover {
        opacity: 1;
        background-color: var(--patients-50);
      }
    }
  }
}

