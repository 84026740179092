@import "scss/colors";
@import "scss/consts";
@import "scss/fonts";

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans Variable', sans-serif;
  color: var(--patients-950);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: clip;
}

ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 0 0 30px;
  padding: 10px 0 5px;
  font-size: inherit;

  li {
    position: relative;

    &::before {
      content: counter(num) '.';
      counter-increment: num;
      font-weight: 700;
      position: absolute;
      top: 0;
      left: -26px;
      width: 20px;
      text-align: right;
    }
  }
}

ul {
  list-style-type: none;
  margin: 0 0 0 36px;
  padding: 0;
  font-size: inherit;

  li {
    line-height: 24px;
    position: relative;
    margin-bottom: 15px;
    min-height: 32px;

    &::before {
      content: '';
      background-color: var(--grayscale-200);
      position: absolute;
      top: 10px;
      left: -25px;
      width: 10px;
      height: 10px;
      border-radius: 20px;
    }
  }
}
