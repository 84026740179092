.caption {
  font-size: 14px;
  line-height: 16px;

  &_L {
    font-weight: 700;
  }

  &_M {
    font-weight: 500;
  }

  &_S {
    font-weight: 400;
  }

  &_XS {
    font-size: 12px;
    font-weight: 700;
  }
}
