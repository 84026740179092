@import 'scss/media';

.eventListItem {
  min-height: 190px;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 24px 40px;
  background-color: var(--providers-50);
  margin-bottom: 24px;

  @include for-size(mobiles) {
    flex-direction: column;
    padding: 24px 12px;
  }

  &_patients {
    background-color: var(--patients-50);
  }

  &_past {
    background-color: var(--grayscale-200);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__info {
    display: flex;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__dates {
    border-right: 1px solid var(--grayscale-200);
    padding-right: 5px;
    margin-top: 26px;
    min-width: 165px;
    height: 92px;

    @include for-size(mobiles) {
      padding-right: 0;
      margin-top: 0;
    }
  }

  &__days {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0 24px;

    @include for-size(mobiles) {
      margin: 0;
    }
  }

  &__address {
    margin-top: 11px;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(mobiles) {
      margin-top: 24px;
      align-items: flex-start;
    }
  }

  &__description {
    white-space: pre-line;
    text-align: right;

    @include for-size(mobiles) {
      margin-top: 24px;
      text-align: left;
    }
  }

  &__tagsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;

    > div {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
