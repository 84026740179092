@import 'scss/media';

.clinicalVideo {
  display: block;
  margin-top: 45px;
  width: 100%;
  height: 700px;
  border: none;

  @include for-size(small-screens) {
    height: 500px;
  }

  @include for-size(tablets) {
    height: 400px;
  }

  @include for-size(mobiles) {
    min-height: 400px;
    height: auto;
  }
}
