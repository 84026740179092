@import 'scss/media';

.nextGenerationSequencing {
  &__blocks {
    display: flex;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 24px;
    padding: 23px 38px 25px 31px;
    border: 1px solid var(--grayscale-200);

    @include for-size(mobiles) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }

    &:last-child {
      margin-right: 0;

      @include for-size(mobiles) {
        margin-bottom: 0;
      }
    }
  }

  &__analysis {
    display: flex;
    margin-top: 36px;

    @include for-size(mobiles) {
      flex-direction: column;
    }

    > div {
      width: calc(100% / 3);
      margin-right: 24px;
      padding-right: 5px;

      @include for-size(mobiles) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
      }

      &:last-child {
        margin-right: 0;

        @include for-size(mobiles) {
          margin-bottom: 0;
        }
      }
    }
  }
}
