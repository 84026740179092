.latestEvent {
  border: 1px solid var(--grayscale-200);
  padding: 14px 24px 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &__title {
    margin-bottom: 7px;
  }

  &__text {
    margin-top: 8px;
  }
}
