@import 'scss/media';

.licenses {
  display: flex;
  justify-content: space-between;
  background-color: var(--providers-50);
  padding: 34px 52px 156px 32px;
  margin-bottom: 114px;

  @include for-size(tablets) {
    flex-direction: column;
  }

  @include for-size(mobiles) {
    flex-direction: column;
    padding: 16px 16px 56px;
    margin-bottom: 64px;
  }

  &_patients {
    background-color: var(--patients-50);
  }

  a {
    text-decoration-color: transparent;
  }

  &__text {
    width: 420px;

    @include for-size(small-screens) {
      width: 300px;
    }

    @include for-size(tablets) {
      width: 100%;
    }

    @include for-size(mobiles) {
      width: 100%;
    }
  }

  &__content {
    margin-top: 2px;
    width: 884px;

    @include for-size(small-screens) {
      width: 700px;
    }

    @include for-size(tablets) {
      margin-top: 24px;
      width: 100%;
    }

    @include for-size(mobiles) {
      margin-top: 24px;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 12px;
  }
}
