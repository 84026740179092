@import 'scss/media';

.pageWithSidebar {
  display: flex;
  width: 100%;

  @include for-size(tablets) {
    flex-direction: column;
  }

  @include for-size(mobiles) {
    flex-direction: column;
  }

  &__sidebar {
    min-width: 348px;
    width: 348px;

    @include for-size(tablets) {
      display: none;
    }

    @include for-size(mobiles) {
      display: none;
    }
  }

  &__content {
    flex-grow: 1;
    margin-left: 12px;
    width: calc(100% - 360px);

    @include for-size(tablets) {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }

    @include for-size(mobiles) {
      margin-left: 0;
      margin-top: 16px;
      width: 100%;
    }
  }
}

