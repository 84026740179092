@import 'scss/media';

.table {
  width: 100%;
  border-top: 1px solid var(--grayscale-200);
  margin-bottom: 24px;
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__row {
    display: flex;

    > div {
      padding: 12px 8px;
      border-bottom: 1px solid var(--grayscale-200);

      .heading_H5 {
        margin-bottom: 0;
      }

      .heading_H6 {
        margin-bottom: 8px;
      }

      @include for-size(mobiles) {
        min-width: 80%;
      }
    }

    &_small {
      > div {
        @include for-size(mobiles) {
          min-width: 40%;
        }
      }
    }

    &_highlighted > div {
      background-color: var(--providers-50);
    }

    &_patients.table__row_highlighted > div {
      background-color: var(--patients-50);
    }
  }
}
