.hero {
  &_L {
    font-weight: 400;
    font-size: var(--font-size-58);
    line-height: var(--font-size-58-lh);
  }

  &_M {
    font-weight: 600;
    font-size: var(--font-size-32);
    line-height: var(--font-size-32-lh);
  }

  &_S {
    font-weight: 300;
    font-size: var(--font-size-32);
    line-height: var(--font-size-32-lh);
  }
}
