@import 'scss/media';

.mobileSearch {
  display: none;
  width: 42px;
  height: 42px;

  @include for-size(mobiles) {
    display: block;
  }

  &__input {
    background-color: var(--providers-50);
    z-index: 1;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    border: 1px solid var(--grayscale-300);
    padding: 0 40px 0 18px;
    font-size: 14px;
    line-height: 20px;
    transition: all .2s ease-in-out;
    right: -100%;

    &.expanded {
      right: 0;
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  &_patients {
    .mobileSearch__input {
      background-color: var(--patients-50);
    }
  }

  &__icon {
    z-index: 2;
    top: 9px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    transition: all .2s ease-in-out;

    &_search {
      &.expanded {
        right: 100%;
        opacity: 0;
      }
    }

    &_cancel {
      right: -40px;
      opacity: 0;

      &.expanded {
        right: 12px;
        opacity: 1;
      }
    }
  }
}
