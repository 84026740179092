@import 'scss/media';

.siteVersionSwitcher {
  position: relative;
  width: 180px;
  height: 40px;
  border: 1px solid var(--patients-950);
  border-radius: 60px;
  margin-left: 80px;

  @include for-size(mobiles) {
    margin-left: 0;
  }

  &__underlay {
    width: 91px;
    height: 34px;
    border-radius: 40px;
    position: absolute;
    left: 2px;
    top: 2px;
    background-color: var(--patients-950);
    transition: 0.3s ease all;
  }

  &__knob {
    position: absolute;
    top: 8px;
    left: 16px;
    color: var(--grayscale-50);
    transition: 0.3s ease all;

    &_right {
      color: var(--patients-950);
      left: auto;
      right: 16px;
    }
  }

  &__checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;

    &:checked {
      ~ .siteVersionSwitcher__underlay {
        left: 95px;
        width: 81px;
      }

      ~ .siteVersionSwitcher__knob {
        color: var(--patients-950);
      }

      ~ .siteVersionSwitcher__knob_right {
        color: var(--grayscale-50);
      }
    }
  }
}
