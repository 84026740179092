@import 'scss/media';

.subset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grayscale-200);
  padding: 20px 0;

  @include for-size(mobiles) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:last-child {
    border-bottom-color: transparent;
    padding-bottom: 0;
  }

  &__left {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 10px;
    }

    @include for-size(mobiles) {
      margin-bottom: 12px;
    }
  }

  &__info {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    color: var(--patients-950);
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
  }

  &__button {
    height: 36px;

    @include for-size(mobiles) {
      margin-left: auto;
    }
  }
}
