@import 'scss/media';

.ancestryAwareSolutions {
  position: relative;
  width: 100%;
  height: 500px;
  padding: 65px 64px 42px;
  margin-top: 32px;
  z-index: 1;

  @include for-size(small-screens) {
    margin-top: 24px;
  }

  @include for-size(tablets) {
    margin-top: 24px;
  }

  @include for-size(mobiles) {
    margin-top: 24px;
    height: 647px;
    padding: 235px 0 0;
  }

  a {
    text-decoration: none;
  }

  &__header {
    display: flex;

    @include for-size(mobiles) {
      padding-left: 64px;
    }
  }

  &__dna {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 68px;
    height: 68px;
    background-color: var(--providers-500);
    border-radius: 60px;

    &_patients {
      background-color: var(--patients-500);
    }

    svg {
      width: 48px;
      height: 48px;

      path {
        stroke: var(--grayscale-50);
      }
    }
  }

  &__heading {
    margin-top: 49px;
    max-width: 400px;

    @include for-size(mobiles) {
      margin-top: 30px;
      max-width: 100%;
      text-align: center;
    }
  }

  &__hero {
    display: block;
    margin-top: 14px;
    max-width: 440px;
    font-size: 24px;
    line-height: 28px;

    @include for-size(mobiles) {
      margin-top: 9px;
      max-width: 100%;
      text-align: center;
    }
  }

  &__button {
    display: inline-block;
    margin-top: 24px;

    @include for-size(tablets) {
      margin-top: 21px;
    }

    @include for-size(mobiles) {
      margin-top: 31px;
      width: 100%;
      padding: 0 36px;

      button {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__subtract {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('assets/path2x.png');
    background-size: cover;
    z-index: -1;

    @include for-size(mobiles) {
      background-position: -320px 0;
    }
  }

  &__video {
    height: 100%;
    z-index: -2;
  }
}
