@import 'scss/media';

.teamMember {
  margin-top: 8px;

  &__header {
    display: flex;
    align-items: center;
  }

  &__leftColumn {
    position: relative;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: var(--patients-950);
    width: 68px;
    height: 68px;
    border-radius: 100%;
    bottom: 11px;
    right: -15px;

    svg {
      width: 48px;
      height: 48px;

      path {
        fill: var(--grayscale-50);
      }
    }
  }

  &__photo {
    width: 172px;
    height: 172px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 11px;
    filter: grayscale(100%);

    @include for-size(mobiles) {
      width: 132px;
      height: 132px;
    }

    > div {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-color: var(--grayscale-200);
    }
  }

  &__rightColumn {
    margin-left: 30px;
    margin-top: 14px;
  }

  &__title {
    margin-bottom: 10px;

    @include for-size(mobiles) {
      word-break: break-word;
    }
  }

  &__jobTitle {
    display: block;
  }

  &__text {
    margin-top: 5px;
  }
}
