@import 'scss/media';

.ourPublications {
  width: 424px;
  margin-right: 56px;

  @include for-size(small-screens) {
    width: calc(50% - 28px);
    margin-right: 0;
    margin-left: 56px;

    &:first-child {
      margin-left: 0;
    }
  }

  @include for-size(tablets) {
    width: calc(50% - 28px);
    margin-right: 0;
    margin-left: 56px;

    &:first-child {
      margin-left: 0;
    }
  }

  @include for-size(mobiles) {
    width: 100%;
    margin-top: 48px;
    margin-right: 0;
  }

  a:hover {
    text-decoration-color: inherit;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--grayscale-200);
  }

  &__links {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
