@import 'scss/media';

.documentsList {
  display: flex;
  flex-wrap: wrap;

  @include for-size(mobiles) {
    flex-direction: column;
  }

  > div {
    width: calc(50% - 50px);
    margin-right: 50px;

    @include for-size(mobiles) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;
    }

    &:last-child {
      margin-right: 0;

      @include for-size(mobiles) {
        margin-bottom: 0;
      }
    }
  }
}
