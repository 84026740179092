@import 'scss/media';

.legalPage {
  &__section {
    margin-bottom: 24px;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--grayscale-200);

    &_lastChildIsTable {
      border-color: transparent;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  :global {
    .heading_H1 {
      margin-bottom: 26px;
    }

    .heading_H4 {
      margin-bottom: 24px;
    }

    .heading_H5 {
      margin-bottom: 12px;
    }

    .caption_M {
      letter-spacing: 1.5px;
      margin-bottom: 12px;
      display: block;
    }

    .body_L {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul, ol {
    font-size: var(--font-size-20);
    line-height: var(--font-size-32);
    margin-bottom: 24px;

    li {
      line-height: inherit;
    }
  }

  &__browsers {
    margin-top: 9px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    @include for-size(mobiles) {
      flex-direction: column;
    }

    a {
      color: var(--patients-950);
      text-decoration: none;
    }
  }

  &__policyFramework {
    display: flex;
    align-items: center;
    padding-right: 48px;

    @include for-size(mobiles) {
      padding-right: 0;
      flex-direction: column;
    }

    > img {
      margin-right: 65px;

      @include for-size(mobiles) {
        margin-bottom: 24px;
        margin-right: 0;
        max-width: 100%;
      }
    }
  }

  &__lifeCycle {
    display: block;
    margin: 0 auto;

    @include for-size(mobiles) {
      max-width: 100%;
    }
  }
}
