@import 'scss/media';

.comparingContent {
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  a {
    text-decoration: none;
  }

  .heading_H4 {
    color: var(--providers-600);
  }

  &__section {
    display: flex;
  }

  &__block {
    position: relative;
    width: 50%;
    margin-right: 24px;
    border-bottom: 1px solid var(--grayscale-200);
    padding-top: 13px;
    padding-bottom: 17px;

    &_subset {
      background-color: var(--providers-50);
      padding-left: 12px;
      padding-right: 12px;

      &::after, &::before {
        content: '';
        position: absolute;
        background-color: inherit;
        height: 1px;
        width: 12px;
        bottom: -1px;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include for-size(mobiles) {
      width: 80%;
      min-width: 80%;
    }
  }

  &_patients {
    .heading_H4 {
      color: var(--patients-600);
    }

    a {
      color: var(--patients-600);
    }

    .comparingContent__block_subset {
      background-color: var(--patients-50);
    }
  }

  &__specification {
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    &:first-of-type {
      margin-top: 19px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
