.gene {
  height: 36px;
  color: var(--patients-950);
  border-radius: 60px;
  text-align: center;
  background-color: var(--grayscale-200);
  white-space: nowrap;
  padding: 7px 16px;
  margin-right: 11px;
  margin-bottom: 11px;

  span {
    letter-spacing: 2px;
  }
}
