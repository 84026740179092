.addressAndDate {
  display: flex;

  > div {
    text-align: right;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  h4 {
    opacity: .8;
  }
}
