@import 'scss/media';

.about {
  width: 456px;
  height: 100%;
  border: 1px solid var(--patients-950);
  padding: 39px 31px;

  a {
    text-decoration: none;
  }

  @include for-size(small-screens) {
    width: 362px;
  }

  @include for-size(tablets) {
    height: 288px;
    display: flex;
    flex-basis: 100%;
    padding: 30px 23px;
  }

  @include for-size(mobiles) {
    width: 100%;
    padding: 39px 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include for-size(tablets) {
      flex-direction: column-reverse;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    span {
      line-height: 38px;
    }
  }

  &__logo {
    width: 48px;
    height: 48px;

    path {
      fill: var(--patients-950);
    }
  }

  &__list {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    @include for-size(tablets) {
      flex-grow: 1;
      margin-left: 230px;
      margin-top: 0;
    }

    @include for-size(mobiles) {
      margin-top: 55px;
    }
  }

  &__link {
    color: var(--patients-950);
    display: inline;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.2s ease-in-out;
  }

  &__listItem {
    margin-bottom: 16px;
    position: relative;

    @include for-size(tablets) {
      margin-bottom: 18px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      background-image: url("~app/icons/ChevronRight.svg");
      width: 24px;
      height: 24px;
    }

    &:hover .about__link {
      border-bottom-color: var(--patients-950);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
