@import 'scss/media';

.productBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  border: 1px solid var(--grayscale-200);
  margin-bottom: 32px;
  padding: 30px 39px;
  overflow: hidden;
  transition: all .2s ease-in-out;
  background: linear-gradient(270deg, var(--grayscale-50) 0%, var(--providers-50) 100%) repeat-y -1000px 0;

  &_patients {
    background: linear-gradient(270deg, var(--grayscale-50) 0%, var(--patients-50) 100%) repeat-y -1000px 0;
  }

  &:hover {
    background-position: 0 0;
  }

  @include for-size(mobiles) {
    height: auto;
  }

  &_small {
    height: 202px;
    min-height: unset;
  }

  &__description {
    position: relative;
    max-width: 554px;
    margin-bottom: 33px;
  }

  &_small &__description {
    max-width: 404px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__bg {
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .2s ease-in-out;

    :hover > & {
      transform: scale(.9);
      border-radius: 20px;
    }

    @include for-size(small-screens) {
      width: 45%;
    }

    @include for-size(tablets) {
      width: 40%;
    }

    @include for-size(mobiles) {
      width: 100%;
      opacity: .1;
    }
  }

  &_small &__bg {
    right: -58px;
    width: 65%;
  }

  &__bgBlur {
    background-image: url('assets/bg_blur.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 421px;
    height: 400px;
  }

  &__title {
    position: relative;
    margin-bottom: 15px;
  }

  &__actions {
    position: relative;
    display: flex;

    a {
      text-decoration: none;
    }
  }

  &__bottomContent {
    max-width: 554px;
    position: relative;
    display: flex;
    margin-top: 64px;

    @include for-size(mobiles) {
      max-width: unset;
      flex-direction: column;
    }
  }
}

