.formControl {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &__input {
    font-size: 14px;
    border-radius: 2px;
    padding: 0 13px;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--grayscale-200);
    transition: all 0.2s ease-in-out;
    margin-top: 5px;

    &:hover {
      border-color: var(--providers-600);
    }

    &:active, &:focus {
      outline: none;
      background-color: var(--providers-50);
      color: var(--providers-600);
    }

    &_textarea {
      resize: none;
      padding: 13px;
      height: 97px;
    }

    &_patients {
      &:hover {
       border-color: var(--patients-600);
      }

      &:active, &:focus {
        background-color: var(--patients-50);
        color: var(--patients-600);
      }
    }

    &_invalid {
      border-color: var(--error);
    }
  }

  &__error {
    position: absolute;
    color: var(--error);
    bottom: -1px;
    animation: error-appear .2s ease-in-out;
    animation-fill-mode: forwards;
  }
}

@keyframes error-appear {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 13px, 0);
  }
}
