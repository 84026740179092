@import 'scss/media';

.howItWorks {
  &__title {
    margin-bottom: 22px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin: 26px 0 31px;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__or {
    position: relative;
    z-index: 1;
    background-color: var(--patients-50);
    width: 40px;
    display: inline-block;
    margin: 6px 0;
  }

  &__separator {
    position: relative;
    width: 100%;
    text-align: center;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      left: 0;
      top: 14px;
      background-color: var(--grayscale-200);
    }
  }

  &__conclusion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 58px;

    @include for-size(mobiles) {
      flex-direction: column;
    }

    > p {
      padding-right: 24px;

      @include for-size(mobiles) {
        text-align: justify;
      }
    }
  }

  &__enroll {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &__explore {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grayscale-200);
    min-width: 552px;
    width: 552px;
    min-height: 88px;
    padding: 0 23px;

    @include for-size(small-screens) {
      min-width: 500px;
      width: 500px;
    }

    @include for-size(tablets) {
      min-width: 320px;
      width: 320px;
      flex-direction: column;
      padding: 12px 23px;
    }

    @include for-size(mobiles) {
      margin-top: 24px;
      min-width: unset;
      width: 100%;
      flex-direction: column;
      padding: 12px 23px;
    }

    a {
      text-decoration: none;

      @include for-size(tablets) {
        margin-top: 12px;
      }

      @include for-size(mobiles) {
        margin-top: 12px;
      }
    }
  }
}
