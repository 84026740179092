@import 'scss/media';

.cardiometabolicConditions {
  display: flex;
  flex-wrap: wrap;

  @include for-size(tablets) {
    padding-right: 10px;
  }

  .icon {
    margin-right: 12px;
  }

  &__item {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    flex: 0 0 calc(100% / 3);

    &:nth-last-child(-n+3) {
      margin-bottom: 0;
    }

    @include for-size(small-screens) {
      flex: 0 0 50%;

      &:nth-last-child(-n+3) {
        margin-bottom: 24px;
      }

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    @include for-size(tablets) {
      flex: 0 0 50%;

      &:nth-last-child(-n+3) {
        margin-bottom: 24px;
      }

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    @include for-size(mobiles) {
      flex: 0 0 100%;

      &:nth-last-child(-n+3) {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
