.tag {
  color: var(--patients-950);
  padding: 0 17px;
  height: 36px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--patients-950);
  background-color: transparent;
  white-space: nowrap;
  margin-bottom: 8px;
}
