.body {
  margin: 0;
  font-weight: 400;

  &_L {
    font-size: var(--font-size-20);
    line-height: var(--font-size-20-lh);
  }

  &_M {
    font-size: 16px;
    line-height: 24px;
  }

  &_S {
    font-size: 14px;
    line-height: 20px;
  }
}
