@import 'scss/media';

.footer {
  display: flex;
  width: var(--container-width);
  margin: 30px auto 0;
  padding: 60px 0;
  border-top: 1px solid transparent;

  &_topBorder {
    border-top-color: var(--patients-950);
  }

  @include for-size(tablets) {
    flex-wrap: wrap;
  }

  @include for-size(mobiles) {
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 310px;

    @include for-size(mobiles) {
      min-width: unset;
      border-bottom: 1px solid var(--grayscale-200);
      padding-bottom: 25px;
    }
  }

  &__logo {
    width: 281px;
    height: 73px;
    margin-bottom: 11px;

    @include for-size(mobiles) {
      margin-bottom: 7px;
    }
  }

  &__contacts {
    display: flex;
    margin-top: 12px;

    @include for-size(mobiles) {
      margin-top: 18px;
    }

    div {
      p {
        margin-bottom: 4px;
      }

      &:first-child {
        margin-right: 51px;
      }
    }

    a {
      color: var(--providers-600);
    }

    &_patients {
      a {
        color: var(--patients-600);
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-top: 21px;
    margin-bottom: 15px;

    @include for-size(mobiles) {
      margin-top: 13px;
      margin-bottom: 13px;
    }

    a {
      width: 32px;
      height: 32px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__menu {
    display: flex;
    margin-left: 50px;
    margin-top: 1px;

    @include for-size(tablets) {
      margin-left: 26px;
    }

    @include for-size(mobiles) {
      flex-wrap: wrap;
      margin-left: 0;
      margin-top: 25px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 250px;

    @include for-size(tablets) {
      max-width: 175px;
    }

    .heading {
      margin-bottom: 16px;
    }
  }

  &__contactUs {
    flex-basis: 100%;

    .footer__column {
      margin-top: 16px;

      > div {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include for-size(tablets) {
          margin-bottom: 0;
          white-space: nowrap;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include for-size(tablets) {
        margin-top: 6px;
        flex-direction: row;
        justify-content: space-between;
        max-width: 100%;
      }

      @include for-size(mobiles) {
        margin-top: 5px;
      }
    }

    @include for-size(tablets) {
      margin-top: 25px;
      padding-top: 24px;
      border-top: 1px solid var(--grayscale-200);
      min-width: 170px;
    }
  }

  &__providerAndPatient {
    margin-right: 100px;
    min-width: 170px;

    @include for-size(small-screens) {
      margin-right: 40px;
      min-width: 160px;
    }

    @include for-size(tablets) {
      margin-right: 35px;
      min-width: 160px;
    }

    @include for-size(mobiles) {
      width: 100%;
      margin-right: 0;
      display: flex;
      border-bottom: 1px solid var(--grayscale-200);
      padding-bottom: 24px;
      margin-bottom: 25px;
    }

    .footer__column:first-child {
      margin-bottom: 32px;

      @include for-size(small-screens) {
        margin-bottom: 18px;
      }

      @include for-size(tablets) {
        max-width: 150px;
      }

      @include for-size(mobiles) {
        margin-right: 30px;
        min-width: 140px;
        margin-bottom: 0;
      }
    }
  }

  &__differenceAndLegal {
    display: flex;
    margin-right: 20px;

    @include for-size(small-screens) {
      margin-right: 30px;
    }

    @include for-size(tablets) {
      margin-right: 0
    }

    @include for-size(mobiles) {
      width: 100%;
      margin-right: 0;
      border-bottom: 1px solid var(--grayscale-200);
      padding-bottom: 24px;
      margin-bottom: 25px;
    }

    .footer__column {
      &:first-child {
        margin-right: 70px;
        min-width: 200px;

        @include for-size(small-screens) {
          min-width: 130px;
          margin-right: 70px;
        }

        @include for-size(tablets) {
          min-width: 130px;
          margin-right: 15px;
        }

        @include for-size(mobiles) {
          margin-right: 30px;
          min-width: 140px;
          max-width: 140px;
        }
      }

      &:last-child {
        min-width: 250px;

        @include for-size(small-screens) {
          min-width: 170px;
        }

        @include for-size(tablets) {
          min-width: 170px;
        }

        @include for-size(mobiles) {
          min-width: unset;
        }
      }
    }
  }

  &__link {
    display: block;
    color: var(--providers-600);
    margin-bottom: 16px;
    transition: all .2s ease-in-out;
    text-decoration: underline;
    text-decoration-color: transparent;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration-color: inherit;
    }

    &_patients {
      color: var(--patients-600);
    }
  }
}
