.assayInformation {
  position: relative;
  overflow: hidden;
  max-height: 315px;
  white-space: pre-line;
  transition: all .2s ease-in-out;

  &_opened {
    max-height: 9999px;
  }

  &__button {
    position: absolute;
    bottom: 17px;
    left: 53%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &__blur {
    background: var(--grayscale-50);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    filter: blur(20px);
  }
}
