@import 'scss/media';

.event {
  &__section {
    margin-bottom: 38px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__contacts {
    display: flex;
    justify-content: space-between;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__locationAndDate {
    width: 423px;

    @include for-size(small-screens) {
      width: 300px;
    }

    @include for-size(tablets) {
      width: 350px;
    }

    @include for-size(mobiles) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__info {
    margin-bottom: 12px;
    border-bottom: 1px solid var(--grayscale-200);
  }

  &__column {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }

  &__icon {
    margin-right: 13px;
  }

  &__mapWrapper {
    border-radius: 12px;
    width: 574px;
    height: 252px;
    overflow: hidden;

    @include for-size(small-screens) {
      width: 450px;
    }

    @include for-size(tablets) {
      width: 500px;
    }

    @include for-size(mobiles) {
      width: 100%;
    }
  }

  &__map {
    width: 100%;
    height: 100%;
  }
}
