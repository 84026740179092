.breadcrumb {
  display: flex;
  margin: 11px 0 0;

  &__link {
    color: var(--patients-950);
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all .2s ease-in-out;
    white-space: nowrap;

    &:visited, &:focus, &:active {
      color: inherit;
    }

    &:hover, &.active {
      border-bottom-color: var(--patients-950);
    }
  }

  &__item {
    white-space: nowrap;
    display: flex;

    .body {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 300px;
    }
  }

  &__arrow {
    cursor: pointer;
    position: relative;
    width: 9px;
    margin: 0 14px;

    div {
      position: relative;
      top: 11px;
      width: 9px;
      height: 1px;
      background-color: var(--patients-950);

      &::after, &::before {
        content: '';
        position: absolute;
        background-color: var(--patients-950);
        height: 1px;
        width: 6px;
        right: -1px;
      }

      &::after {
        top: -2px;
        transform: rotate(45deg);
      }

      &::before {
        top: 2px;
        transform: rotate(-45deg);
      }
    }
  }
}
