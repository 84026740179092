@import 'scss/media';

.fullTestsCatalog {
  position: relative;
  width: 456px;
  overflow: hidden;
  height: 424px;
  cursor: pointer;

  @include for-size(small-screens) {
    width: 556px;
    height: 320px;
    margin: 0 24px 0 0;
  }

  @include for-size(tablets) {
    width: 436px;
    height: 320px;
    margin: 0 24px 0 0;
  }

  @include for-size(mobiles) {
    width: 100%;
    height: 240px;
    margin: 12px 0;
  }

  &__arrow {
    position: absolute;
    right: 32px;
    bottom: 32px;
    opacity: 0;
    transition: opacity .1s ease;
  }

  &__layer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 224px;
    position: relative;
    padding: 24px;
    height: 100%;
    background-color: var(--patients-950);
    transition: background-color .25s ease;
  }

  &.animated &__layer {
    animation: left-bump .5s ease-in-out;
  }

  &__logoBg {
    background-color: var(--patients-950);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 68px;
    height: 68px;
    transition: background-color .25s ease;
  }

  &:hover &__layer {
    animation: right-bump .5s ease-in-out;
    animation-fill-mode: forwards;
    background-color: var(--providers-500);
  }

  &:hover &__layer_patients {
    background-color: var(--patients-500);
  }

  &:hover &__arrow {
    opacity: 1;
  }

  &:hover &__logoBg {
    background-color: #164365;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('assets/providers.jpg');
    background-position: 110px -33px;
    filter: brightness(1.5);

    @include for-size(small-screens) {
      background-position: 110px -85px;
    }

    @include for-size(tablets) {
      background-position: 110px -85px;
    }

    @include for-size(mobiles) {
      background-size: cover;
      background-position: 110px 50%;
    }

    &_patients {
      background-image: url('assets/patients.jpg');
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: var(--grayscale-50);
  }

  &__logo {
    width: 48px;
    height: 48px;

    path {
      fill: var(--grayscale-50);
    }
  }
}

@keyframes right-bump {
  0% { width: 184px; }
  50% { width: calc(100% + 24px); }
  75% { width: calc(100% - 6px) }
  100% { width: 100%; }
}

@keyframes left-bump {
  0% { width: 100%; }
  50% { width: 184px; }
  100% { width: 224px }
}
