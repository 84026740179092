@import 'scss/media';

.logo {
  &__wrapper {
    display: flex;
    align-items: center;

    @include for-size(mobiles) {
      max-width: 48px;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    position: absolute;
    opacity: 1;
    transition: opacity .3s ease-in-out;

    &_colored {
      opacity: 0;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0;
    transition: all .3s ease-in-out;
    background-color: transparent;
    border: none;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__mobileIcon {
    position: relative;
    height: 48px;
    width: 48px;
    display: none;

    @include for-size(mobiles) {
      display: block;
    }
  }

  &__icons {
    position: relative;
    height: 48px;
    width: 48px;
    margin-right: 9px;

    @include for-size(mobiles) {
      margin-right: 0;
      display: none;
    }

    &:hover {
      .logo__icon {
        opacity: 0;

        &_colored {
          opacity: 1;
        }
      }

      .logo__arrow {
        opacity: 1;
        left: -35px;
      }
    }
  }

  &__galatea {
    opacity: 1;
    transition: all .3s ease-in-out;

    &_hovered {
      transform: translateY(19px);
    }
  }

  &__homepage {
    position: absolute;
    opacity: 0;
    bottom: -20px;
    transition: all .3s ease-in-out;
    pointer-events: none;
  }

  &__clinicalTesting {
    transition: all .3s ease-in-out;
    margin-top: 4px;

    &_hovered {
      opacity: 0;
    }
  }

  &__backTo {
    position: absolute;
    opacity: 0;
    bottom: 20px;
    transition: opacity .3s ease-in-out;
    pointer-events: none;

    &_hovered {
      opacity: 1;
    }
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    color: inherit;

    @include for-size(mobiles) {
      display: none;
    }

    &:hover {
      .logo__homepage {
        bottom: 1px;
        opacity: 1;
      }

      .logo__clinicalTesting {
        transform: translateY(-20px);
      }

      .logo__galatea {
        opacity: 0;
      }
    }
  }
}
