.collapsableBlock {
  border-top: 1px dotted var(--grayscale-300);
  padding: 24px 0 15px;

  &__content {
    margin-top: 10px;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease-out;

    &_opened {
      grid-template-rows: 1fr;
    }
  }

  &__inner {
    overflow: hidden;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--providers-600);

    &_patients {
      color: var(--patients-600);
    }
  }

  &__title {
    cursor: pointer;
  }

  &__icon {
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      border-color: var(--patients-950);
    }

    &_opened {
      background-color: var(--grayscale-200);
      transform: rotate(180deg);
    }
  }
}
