.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 48px;
    border: 1px solid var(--grayscale-200);
    border-radius: 50px;
    background-color: var(--grayscale-50);
}
