.aboutLink {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  transition: all .2s ease-in-out;

  &:hover {
    text-decoration-color: inherit;
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 10px;
    min-width: 24px;
    min-height: 24px;

    path {
      stroke: var(--providers-600);
    }
  }

  &_patients {
    svg {
      path {
        stroke: var(--patients-600);
      }
    }
  }
}
