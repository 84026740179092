@import 'scss/media';

.resources {
  a {
    text-decoration: none;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__items {
    width: 696px;

    @include for-size(small-screens) {
      width: 450px;
    }

    @include for-size(tablets) {
      width: 550px;
    }

    @include for-size(mobiles) {
      width: 100%
    }
  }
}
