@import 'scss/media';

.orderTestItem {
  width: 100%;
  height: 100px;
  border: 1px solid var(--grayscale-200);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 19px;

  > svg {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  &__arrow {
    transform: rotate(90deg);
    position: absolute;
    right: 23px;
    bottom: -29px;
    z-index: 1;

    @include for-size(mobiles) {
      right: calc(50% - 18px);
      bottom: -20px;
      top: auto;
      min-width: 36px;
      min-height: 36px;
    }

    svg {
      width: 24px;
      height: 24px;

      @include for-size(mobiles) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;

    .orderTestItem__arrow {
      display: none;
    }
  }
}
