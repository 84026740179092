@import 'scss/media';

.leadershipTeam {
  &__section {
    margin-bottom: 64px;
    border-bottom: 1px solid var(--grayscale-200);

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &__list {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;

    @include for-size(mobiles) {
      justify-content: center;
    }
  }
}
