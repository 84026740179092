@import 'scss/media';

.browser {
  width: 246px;
  height: 134px;
  border: 1px solid var(--providers-600);
  padding: 19px 0 0 15px;

  @include for-size(small-screens) {
    width: 180px;
  }

  @include for-size(tablets) {
    width: 200px;
  }

  @include for-size(mobiles) {
    width: 100%;
    margin-bottom: 24px;
  }

  &:last-child {
    @include for-size(mobiles) {
      margin-bottom: 0;
    }
  }

  &_patients {
    border: 1px solid var(--patients-600);
  }

  &__title {
    margin-top: 12px;
    white-space: pre-line;
  }
}
