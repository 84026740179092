@import 'scss/media';

.faq {
  h1 {
    margin-bottom: 33px;
  }

  h3 {
    margin-bottom: 12px;
  }

  &__list {
    font-size: var(--font-size-20);
    line-height: var(--font-size-20-lh);
    margin-bottom: 5px;
  }

  &__section {
    border-bottom: 1px solid var(--grayscale-200);
    margin-bottom: 32px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__download {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &_nested {
      margin-top: 32px;
      margin-bottom: 0;
    }

    @include for-size(mobiles) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }

    > a {
      text-decoration: none;
      margin-right: 24px;

      @include for-size(mobiles) {
        margin-right: 0;
      }
    }

    > p {
      @include for-size(mobiles) {
        margin-top: 16px;
      }
    }
  }
}
