.heading {
  font-weight: 400;
  margin: 0;

  &_H1 {
    font-size: var(--font-size-42);
    line-height: var(--font-size-42-lh);
  }

  &_H2 {
    font-size: 36px;
    line-height: 48px;
  }

  &_H3 {
    font-size: var(--font-size-32);
    line-height: var(--font-size-32-lh);
  }

  &_H4 {
    font-weight: 600;
    font-size: var(--font-size-28);
    line-height: var(--font-size-28-lh);
  }

  &_H5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &_H6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}
