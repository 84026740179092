@import 'scss/media';

.howItWorksItem {
  width: 192px;
  height: 196px;
  border: 1px solid var(--grayscale-200);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
  padding: 19px 15px 23px;

  @include for-size(small-screens) {
    margin-right: 16px;
  }

  @include for-size(mobiles) {
    width: 100%;
    height: 128px;
    margin-right: 0;
    margin-bottom: 24px;
  }

  > svg {
    width: 36px;
    height: 36px;
  }

  &__arrow {
    position: absolute;
    right: -13px;
    top: 10px;

    @include for-size(mobiles) {
      transform: rotate(90deg);
      right: calc(50% - 18px);
      bottom: -20px;
      top: auto;
      min-width: 36px;
      min-height: 36px;
    }

    svg {
      width: 24px;
      height: 24px;

      @include for-size(mobiles) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &:last-child {
    margin-right: 0;

    @include for-size(mobiles) {
      margin-bottom: 0;
    }

    .howItWorksItem__arrow {
      display: none;
    }
  }
}
