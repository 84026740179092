@import 'scss/media';

.orderTest {
  a {
    text-decoration: none;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__elis {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include for-size(mobiles) {
      flex-direction: column;
    }
  }

  &__items {
    width: 696px;

    @include for-size(small-screens) {
      width: 450px;
    }

    @include for-size(tablets) {
      width: 550px;
    }

    @include for-size(mobiles) {
      width: 100%
    }
  }

  &__download {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 336px;
    border: 1px solid var(--grayscale-200);
    padding-top: 126px;
    background: repeating-linear-gradient(
      135deg,
      var(--grayscale-100) 0,
      var(--grayscale-100) .5px,
      var(--grayscale-50) 1px,
      var(--grayscale-50) 20px
    );

    @include for-size(small-screens) {
      width: 300px;
    }

    @include for-size(tablets) {
      width: 300px;
    }

    @include for-size(mobiles) {
      padding: 63px 0;
      margin-top: 24px;
      width: 100%
    }
  }

  &__guide {
    background-image: url('assets/bg.svg');
    background-color: var(--grayscale-50);
    width: 220px;
    height: 320px;
    border: 2px solid var(--providers-600);
    border-radius: 12px;
    margin-top: 25px;
    padding: 85px 18px 0;

    > span {
      display: block;
      margin-bottom: 7px
    }
  }

  &__logo {
    display: block;
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }

  &__button {
    margin-top: 26px;

    > button {
      width: 220px;

      > span {
        display: block;
        width: 100%;
      }
    }
  }
}
