.comparing {
  &__howToOrder {
    svg {
      margin-right: 8px;

      path {
        stroke: var(--grayscale-50);
      }
    }
  }
}
