@import 'scss/media';

.sampleCollectionInstructions {
  height: 160px;
  display: flex;
  margin-top: 24px;
  align-items: center;

  @include for-size(small-screens) {
    height: 200px;
  }

  @include for-size(mobiles) {
    height: auto;
    flex-direction: column;
  }

  &__text {
    padding-right: 48px;

    @include for-size(small-screens) {
      padding-right: 16px;
    }

    @include for-size(mobiles) {
      text-align: justify;
      padding-right: 0;
    }
  }

  &__items {
    margin: 15px 0 59px;

    @include for-size(small-screens) {
      margin-top: 32px;
    }
  }

  &__precautions {
    height: 100%;
    width: 576px;
    min-width: 576px;
    display: flex;
    align-items: center;
    background-color: rgba(#ED5B3B, 10%);
    padding: 0 24px;

    @include for-size(small-screens) {
      width: 450px;
      min-width: 450px;
    }

    @include for-size(mobiles) {
      margin-top: 16px;
      width: 100%;
      min-width: unset;
      padding: 16px;
    }

    &Image {
      min-width: 112px;
      min-height: 112px;
      margin-right: 24px;
      height: 112px;
      width: 112px;
      border-radius: 100%;
      background-color: var(--grayscale-50);

      @include for-size(mobiles) {
        min-width: 80px;
        min-height: 80px;
        height: 80px;
        width: 80px;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: var(--error)
      }
    }

    &Title {
      margin-bottom: 11px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include for-size(mobiles) {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      @include for-size(mobiles) {
        text-align: justify;
      }
    }

    &Image {
      display: flex;
      margin-right: 24px;
      min-width: 148px;
      min-height: 148px;
      height: 148px;
      width: 148px;

      svg {
        width: 100%;
        height: 100%;

        path, circle, line, polygon, polyline {
          fill: none;
          stroke: var(--providers-500);
          stroke-width: .75px;
        }

        circle {
          stroke-width: 3px;
        }
      }

      &_patients {
        svg {
          path, circle, line, polygon, polyline {
            stroke: var(--patients-500);
          }
        }
      }

      @include for-size(mobiles) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}
