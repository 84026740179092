.panelContent {
  .heading_H4 {
    margin-bottom: 15px;
  }

  &__section {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--grayscale-200);
  }

  &__conditions {
    margin-top: 15px;
  }

  &__sampleReport {
    display: flex;
  }
}
