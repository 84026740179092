@import 'scss/media';

:root {
  --scale: 1;

  @include for-size(tablets) {
    --scale: .8;
  }

  @include for-size(mobiles) {
    --scale: .8;
  }
}

.relentlessCommitment {
  display: flex;
  width: 100%;
  height: 500px;
  padding-left: 70px;
  margin-top: 32px;
  background-color: var(--providers-50);

  @include for-size(small-screens) {
    margin-top: 24px;
  }

  @include for-size(tablets) {
    padding-left: 22px;
    margin-top: 24px;
    height: 745px;
    flex-direction: column-reverse;
  }

  @include for-size(mobiles) {
    padding-left: 0;
    margin-top: 24px;
    height: 876px;
    flex-direction: column-reverse;
  }

  &_patients {
    background-color: var(--patients-50);
  }

  a {
    text-decoration: none;
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include for-size(tablets) {
      align-items: center;
      margin-bottom: 40px;
    }

    @include for-size(mobiles) {
      align-items: center;
    }
  }

  &__title {
    margin-top: 119px;
    max-width: 500px;

    @include for-size(small-screens) {
      margin-top: 75px;
      max-width: 444px;
    }

    @include for-size(tablets) {
      margin-top: 0;
      max-width: 510px;
      text-align: center;
    }

    @include for-size(mobiles) {
      margin-top: 0;
      max-width: 444px;
      text-align: center;
    }
  }

  &__body {
    margin-top: 24px;
    max-width: 525px;

    @include for-size(small-screens) {
      max-width: 444px;
    }

    @include for-size(tablets) {
      margin-top: 13px;
      max-width: 560px;
      text-align: center;
    }

    @include for-size(mobiles) {
      margin-top: 20px;
      max-width: 100%;
      text-align: justify;
      padding: 0 24px;
    }
  }

  &__button {
    display: inline-block;
    margin-top: 24px;

    @include for-size(tablets) {
      margin-top: 12px;
      margin-bottom: 23px;
    }

    @include for-size(mobiles) {
      margin-bottom: 39px;
      width: 100%;
      padding: 0 24px;

      button {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    svg {
      margin-left: -5px;
      margin-right: 11px;

      path {
        stroke: var(--grayscale-50);
      }
    }
  }

  &__images {
    position: relative;
    flex-grow: 1;
    overflow-x: clip;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 338px;
    border: 4px solid var(--grayscale-50);
    border-radius: 24px;
    box-shadow: 28px 205px 58px 0 rgb(0 0 0 / 0%), 18px 131px 53px 0 rgb(0 0 0 / 1%), 10px 74px 45px 0 rgb(0 0 0 / 5%), 4px 33px 33px 0 rgb(0 0 0 / 9%), 1px 8px 18px 0 rgb(0 0 0 / 10%);
  }

  &__image1 {
    z-index: 1;
  }

  .animated &__image1 {
    animation: image1-appearing .8s ease-in-out;
    animation-fill-mode: forwards;
  }

  .animated &__image2 {
    animation: image2-appearing .8s ease-in-out;
    animation-fill-mode: forwards;
  }

  &__frame {
    z-index: 3;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
  }

  .animated &__frame {
    animation: frame-appearing .8s ease-in-out;
    animation-fill-mode: forwards;
  }
}

@keyframes image1-appearing {
  0% { transform: translate(-50%, -50%) rotate(0) scale(var(--scale)) }

  50% { transform: translate(-50%, -50%) rotate(-15deg) scale(var(--scale)) }

  100% { transform: translate(-50%, -50%) rotate(-6.57deg) scale(var(--scale)) }
}

@keyframes image2-appearing {
  0% { transform: translate(-50%, -50%) rotate(0) scale(var(--scale)) }

  50% { transform: translate(-50%, -50%) rotate(15deg) scale(var(--scale)) }

  100% { transform: translate(-50%, -50%) rotate(7.31deg) scale(var(--scale)) }
}

@keyframes frame-appearing {
  0% { transform: translate(-50%, 40px) scale(var(--scale)) }

  100% { transform: translate(-50%, 0) scale(var(--scale)) }
}
