@import "scss/media";

.aaPRSItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 201px;
  padding: 16px 20px 33px;

  @include for-size(small-screens) {
    width: 150px;
  }

  @include for-size(tablets) {
    width: 170px;
  }

  @include for-size(mobiles) {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-bottom: 27px;
  }

  h5 {
    white-space: pre-line;

    @include for-size(mobiles) {
      text-align: center;
    }
  }

  p {
    margin-top: 13px;

    @include for-size(mobiles) {
      text-align: justify;
    }
  }

  &__arrow {
    position: absolute;
    right: -27px;
    top: 49px;
    z-index: 1;

    @include for-size(mobiles) {
      transform: rotate(90deg);
      right: calc(50% - 18px);
      bottom: -20px;
      top: auto;
      min-width: 36px;
      min-height: 36px;
    }

    svg {
      width: 24px;
      height: 24px;

      @include for-size(mobiles) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &:last-child &__arrow {
    display: none;
  }
}
