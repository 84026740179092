@import 'scss/media';

.latestEvents {
  width: 456px;

  @include for-size(small-screens) {
    margin-top: 48px;
    width: calc(50% - 28px);
  }

  @include for-size(tablets) {
    margin-top: 48px;
    width: calc(50% - 28px);
  }

  @include for-size(mobiles) {
    margin-top: 48px;
    width: 100%;
  }

  a:hover {
    text-decoration-color: inherit;
  }

  &__topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }
}
