@import 'scss/media';

.productCatalog {
  margin: 38px 0 0;

  &__genes {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;

    @include for-size(tablets) {
      max-width: 340px;
    }

    @include for-size(mobiles) {
      max-width: unset;
      margin-top: 16px;
    }

    .icon {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__total {
    max-width: 40px;
    margin-left: 9px;
  }
}
