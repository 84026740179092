@import 'scss/media';

.healthRiskScoring {
  display: flex;
  flex-direction: column;

  &__graphic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 51px 43px 38px 93px;
    margin-top: 49px;
    color: var(--providers-600);

    @include for-size(small-screens) {
      padding: 51px 15px 38px;
    }

    @include for-size(tablets) {
      padding: 51px 43px 38px;
    }

    @include for-size(mobiles) {
      flex-direction: column;
      padding: 32px 0;
    }

    &_patients {
      color: var(--patients-600);
    }
  }

  &__icon {
    margin-bottom: 10px;

    path {
      stroke: var(--providers-600)
    }

    &_patients {
      path {
        stroke: var(--patients-600)
      }
    }
  }

  &__prs {
    text-align: center;
    margin-right: 25px;

    @include for-size(small-screens) {
      margin-right: 0;
    }

    @include for-size(tablets) {
      margin-right: 0;
    }

    @include for-size(mobiles) {
      margin-right: 0;
      margin-bottom: 29px;
    }
  }

  &__plus {
    width: 60px;
    height: 60px;
    position: relative;
    margin-bottom: 29px;

    @include for-size(mobiles) {
      margin-top: 29px;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      background-color: var(--providers-600);
    }

    &::before {
      width: 100%;
      height: 7px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &::after {
      width: 7px;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translate(-50%);
    }

    &_patients {
      &::before, &::after {
        background-color: var(--patients-600);
      }
    }
  }

  &__equal {
    width: 57px;
    height: 34px;
    position: relative;
    margin-bottom: 14px;

    @include for-size(mobiles) {
      margin-bottom: 29px;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 8px;
      background-color: var(--providers-600);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &_patients {
      &::before, &::after {
        background-color: var(--patients-600);
      }
    }
  }

  &__aaprs {
    font-size: 68px;
    font-weight: 500;
    line-height: 81.6px;
    margin-bottom: 12px;
  }

  &__howItems {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: 33px;

    @include for-size(mobiles) {
      flex-direction: column;
      align-items: center;
    }
  }
}
