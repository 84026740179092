@import 'scss/media';

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  width: var(--container-width);
  margin: 0 auto;

  @include for-size(mobiles) {
    justify-content: flex-end;
  }

  &__leftColumn {
    @include for-size(mobiles) {
      display: none;
    }
  }

  &__rightColumn {
    @include for-size(mobiles) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__sidebar {
    position: relative;
    display: none;
    background-color: var(--grayscale-50);

    @include for-size(tablets) {
      display: block;
    }

    @include for-size(mobiles) {
      display: block;
    }
  }

  &__blur {
    display: none;

    @include for-size(mobiles) {
      display: block;
      background: var(--grayscale-50);
      position: absolute;
      right: 10px;
      bottom: 0;
      width: 15px;
      height: 100%;
      filter: blur(3px);
    }
  }
}
