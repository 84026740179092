@import 'scss/media';

.teamMemberListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 74px;
  flex-basis: 25%;

  @include for-size(small-screens) {
    flex-basis: calc(100% / 3);
  }

  @include for-size(tablets) {
    flex-basis: calc(100% / 3);
  }

  @include for-size(mobiles) {
    flex-basis: 100%;
  }

  &__photo {
    width: 216px;
    height: 216px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 11px;
    filter: grayscale(100%);

    > div {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-color: var(--grayscale-200);
      transition: transform .3s ease-in-out;

      &:hover {
        transform: scale(1.055);
      }
    }
  }

  &__jobTitle {
    color: var(--grayscale-300);
    padding: 0 30px;
  }
}
