.insurance {
  &__section {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--grayscale-200);

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &__date {
    margin-bottom: 15px;
  }
}
