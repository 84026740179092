@import 'scss/media';

.topMenu {
  display: flex;
  margin-left: 56px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @include for-size(tablets) {
    margin-top: 20px;
    margin-left: 0;
    flex-basis: 100%;
  }

  @include for-size(tablets) {
    display: none;
  }

  @include for-size(mobiles) {
    display: none;
  }

  &__submenu {
    z-index: 1;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    pointer-events: none;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 28px;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 100vw;
      height: calc(100% - 15px);
      background-color: var(--grayscale-50);
      border-bottom: 1px solid var(--grayscale-100);
      z-index: -1;
    }

    &::before {
      right: 0;
    }

    &::after {
      left: 0;
    }
  }

  &__item {
    margin-right: 20px;

    &_hovered .topMenu__submenu {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: var(--patients-950);
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all .2s ease-in-out;
    white-space: nowrap;

    &:hover, &.active {
      border-bottom-color: var(--patients-950);
    }

    &_sub {
      margin-bottom: 16px;
    }
  }
}
