.videoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(0deg, rgb(255 255 255 / 100%) 26%, rgb(115 115 115 / 0%) 100%);

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}
