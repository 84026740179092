.container {
  position: relative;
  margin: 0 auto;
  width: var(--container-width);
  flex-grow: 1;
  padding-top: 30px;

  a {
    color: var(--providers-600);
    text-decoration: underline;
  }

  &_patients {
    a {
      color: var(--patients-600);
    }
  }
}

.fadeInLeft {
  animation: fade-in-left 1s ease-in-out;
}

.fadeInRight {
  animation: fade-in-right 1s ease-in-out;
}

@keyframes fade-in-left {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  25% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  30% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  40% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  55% {
    transform: translate3d(-10px, 0, 0);
  }

  75% {
    transform: translate3d(5px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  25% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  30% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  40% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  55% {
    transform: translate3d(-10px, 0, 0);
  }

  75% {
    transform: translate3d(5px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
