@import 'scss/media';

:root {
  --container-width: 1416px;
}

@include for-size(small-screens) {
  :root {
    --container-width: 1136px;
  }
}

@include for-size(tablets) {
  :root {
    --container-width: 896px;
  }
}

@include for-size(mobiles) {
  :root {
    --container-width: calc(100% - 32px);
  }
}
